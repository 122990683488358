import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from './interceptor';
import { getAcquirerId } from '../../components/auth/UserData';

export const getMerchants = createAsyncThunk('merchant/get', async () => {
  const response = await api.get(`/portalapi/merchant`);
  return response.data;
});

export const getMerchant = createAsyncThunk('merchant/get', async id => {
  const response = await api.get(`/portalapi/merchant/${id}`);
  return response.data;
});

export const registerMerchant = createAsyncThunk(
  'merchant/register',
  async data => {
    data = {
      acquirerId: getAcquirerId(),
      name: data.businessName,
      document: data.document,
      technicalContact: {
        name: data.contactName,
        phone: data.contactPhone,
        email: data.contactEmail,
      },
      addresses: [
        {
          type: 'MAIN',
          street: data.street,
          number: data.number,
          complement: data.complement,
          zipCode: data.zipCode,
          city: data.city,
          state: data.state,
          country: data.country,
        },
      ],
    };
    const response = await api.post(`/portalapi/merchant`, data);
    return response.data;
  }
);

export const deleteMerchant = createAsyncThunk('merchant/delete', async id => {
  try {
    const response = await api.delete(`/portalapi/merchant/${id}`);
    return response.data;
  } catch (error) {}
});

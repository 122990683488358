import React, { useEffect, useState, useRef } from 'react';
import {
  Flex,
  Box,
  Button,
  ButtonGroup,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Stack,
  StackDivider,
  Spacer,
  Text,
  Select,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerCloseButton,
  DrawerFooter,
  DrawerOverlay,
  VStack,
  Skeleton,
  Heading,
} from '@chakra-ui/react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { SearchIcon, RepeatIcon, DownloadIcon } from '@chakra-ui/icons';
import { MdOutlineSave, MdOutlineFilterAlt } from 'react-icons/md';
import { DataTable } from 'primereact/datatable';
import { exportToCSV } from '../../components/export/exportToCSV';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { useDispatch } from 'react-redux';
import {
  getTransactions,
  getTotalTransactions,
} from '../../redux/action/dashboard';
import { getAcquirer } from '../../redux/action/acquirer';
import Invoice from './Invoice';
import { useTranslation } from 'react-i18next';
import colors from '../../assets/custom/colors';
import moment from 'moment';

const formatDate = dateString => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const DataProcess = ({ totalTransactionsCallback }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [acquirerInfo, setAcquirerInfo] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [filters, setFilters] = useState({
    list_status_transaction: '',
    date_begin_end: '',
  });

  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstFieldRef = useRef(null);
  const list_status = ['SUCCESS', 'REJECTED', 'PENDING'];
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    const fetchDataTransaction = async () => {
      try {
        const today = moment().format('YYYY-MM-DD');
        const firstDate = moment().subtract(60, 'days').format('YYYY-MM-DD');

        const transactionData = await dispatch(
          getTransactions({ dateFrom: firstDate, dateTo: today })
        ).unwrap();
        const totalTransactions = await dispatch(
          getTotalTransactions({ dateFrom: firstDate, dateTo: today })
        ).unwrap();
        transactionData.map(m => {
          if (m.recipientAmount && m.settlementAmount) {
            m.recipientAmount = m.recipientAmount;
            m.settlementAmount = m.settlementAmount.toFixed(2);
          } else {
            if (m.recipient?.amount) {
              m.recipient.amount = m.recipient.amount.toFixed(2);
            }
            if (m.settlementAmount) {
              m.settlementAmount = m.settlementAmount.toFixed(2);
            }
          }
        });
        setTransactions(transactionData);
        totalTransactionsCallback(totalTransactions);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchDataTransaction();
  }, [dispatch]);

  const handleSearch = event => setSearchTerm(event.target.value);
  const handleFilterSearch = async () => {
  const { date_begin_end, list_status_transaction } = filters;

    if (list_status_transaction === '' && (date_begin_end === '' || date_begin_end === undefined)) {
      return false;
    }

    if(list_status_transaction !== '' && date_begin_end === '' || date_begin_end === undefined) {
      refreshDataTable(list_status_transaction);
    }else{

      setLoading(false);
      const start_date = date_begin_end[0];
      const end_date = date_begin_end[1];


      try {
        const transactionsData = await dispatch(
          getTransactions({
            dateFrom: moment(start_date).format('YYYY-MM-DD'),
            dateTo: moment(end_date).format('YYYY-MM-DD'),
          })
        ).unwrap();

        const totalTransactionsData = await dispatch(
          getTotalTransactions({
            dateFrom: formatDate(start_date),
            dateTo: formatDate(end_date),
          })
        ).unwrap();


        if (list_status_transaction) {
          const transactionsStatus = transactionsData.filter(
            transaction => transaction.status === list_status_transaction
          );

          setTransactions(transactionsStatus);
        } else {
          setTransactions(transactionsData);
          totalTransactionsCallback(totalTransactionsData);
        }

        setLoading(false);

      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleOpenDetails = async transaction => {
    const acquirer = await dispatch(
      getAcquirer(transaction.acquirerId)
    ).unwrap();

    let bic = transaction.accountNumberRecipient.split(';')[1]
      ? transaction.accountNumberRecipient.split(';')[1].split('=')
      : '';
    let ac = {
      name: acquirer.businessName,
      address: `${acquirer.addresses[0].street} ${acquirer.addresses[0].number} ${acquirer.addresses[0].city} ${acquirer.addresses[0].state}`,
      bic: bic[1] || '',
    };
    setAcquirerInfo(ac);
    setSelectedTransaction(transaction);
    setShowDetail(true);
  };

  const getAcquirerName =  async acquirerId => {
    const acquirer = await dispatch(
      getAcquirer(acquirerId)
    ).unwrap();

    return acquirer.businessName;
  }

  const handleCloseDetails = () => setShowDetail(false);
  const clearFields = () => {
    setFilters('');
  };
  const refreshDataTable = async (statusTransactions) => {
    try {
      setLoading(true);

      const today = moment().format('YYYY-MM-DD');
      const firstDate = moment().subtract(60, 'days').format('YYYY-MM-DD');

      let transactionData = await dispatch(
        getTransactions({ dateFrom: firstDate, dateTo: today })
      ).unwrap();
      const totalTransactions = await dispatch(
        getTotalTransactions({ dateFrom: firstDate, dateTo: today })
      ).unwrap();
      
      if(statusTransactions){
        transactionData = transactionData.filter(
          transaction => transaction.status === statusTransactions
        );
      }

      setTransactions(transactionData);
      totalTransactionsCallback(totalTransactions);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const exportData = async () => {
    const acquirerName = await getAcquirerName(transactions[0].acquirerId);
    const csvData = transactions.map(transaction => ({
      [t(`dashboard.transactionDate`)]: transaction.timestamp,
      [t(`dashboard.originatingInstitution`)]: acquirerName,
      [t(`dashboard.transactionReferenceId`)]: transaction.quoteId,
      [t(`dashboard.sendersName`)]: transaction.fullNameSender,
      [t(`dashboard.recipientsAccountNumber`)]: transaction.accountNumberRecipient,
      [t(`dashboard.recipientsName`)]: transaction.fullNameRecipient,
      [t(`dashboard.paymentType`)]: transaction.paymentTypeRecipient,
      [t(`dashboard.amountSent`)]: transaction.recipientAmount,
      [t(`dashboard.currencySent`)]: transaction.recipientCurrencyCode,
      [t(`dashboard.settledAmount`)]: transaction.settlementAmount,
      [t(`dashboard.settledCurrency`)]: transaction.settlementCurrencyCode,
      [t(`dashboard.transactionStatus`)]: transaction.status,

    }));

    exportToCSV(csvData, moment().format('YYYY_MM_DD'));
  };

  const columns = [
    { field: 'timestamp', header: 'transactionDate' },
    { field: 'fullNameSender', header: 'senderName' },
    { field: 'paymentTypeRecipient', header: 'typeOfOperation'},
    { field: 'fullNameRecipient', header: 'recipientName'},
    { field: 'recipientAmountCode', header: 'sent'},
    { field: 'settledAmountCode', header: 'settled'},
    { field: 'status', header: 'status' },
    {
      field: 'iconView',
      header: 'iconView',
      body: rowData => (
        <SearchIcon
          marginLeft={4}
          cursor={'pointer'}
          onClick={() => handleOpenDetails(rowData)}
        />
      ),
    },
  ];

  return (
    <>
      <Flex direction="row" alignItems="center" textAlign="center" py={3}>
        <Box>
          <Popover
            isOpen={isOpen}
            initialFocusRef={firstFieldRef}
            onOpen={onOpen}
            onClose={onClose}
            closeOnBlur={false}
            placement="right"
          >
            <PopoverTrigger>
              <Button border={'1px solid'} mx={1} variant="outline">
                <MdOutlineFilterAlt />
              </Button>
            </PopoverTrigger>
            <PopoverContent ml={2}>
              <PopoverArrow />
              <PopoverCloseButton />
              <Stack spacing={1} p={4}>
                <FormLabel>{t(`dashboard.status`)}</FormLabel>
                <Select
                  id="status"
                  onChange={e =>
                    setFilters({
                      ...filters,
                      list_status_transaction: e.target.value,
                    })
                  }
                  placeholder={t(`dashboard.selectStatus`)}
                  value={filters.list_status_transaction || ''}
                >
                  {list_status.map(status => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </Select>

                <FormLabel mt={9}>
                  {t(`dashboard.startDate`)} - {t(`dashboard.endDate`)}
                </FormLabel>
                <Box zIndex="tooltip">
                  <Calendar
                    name="date_begin_end"
                    maxDate={new Date()}
                    value={filters.date_begin_end || ''}
                    onChange={e =>
                      setFilters({ ...filters, date_begin_end: e.value })
                    }
                    selectionMode="range"
                    dateFormat="dd/mm/yy"
                    readOnlyInput
                    hideOnRangeSelection
                    
                  />
                </Box>

                <ButtonGroup mt={9} display="flex" justifyContent="flex-end">
                  <Button
                    variant="outline"
                    onClick={e => {
                      e.preventDefault();
                      clearFields();
                    }}
                    color="teal.500"
                    mr={0}
                  >
                    {t(`dashboard.clear`)}
                  </Button>
                  <Button variant="outline" onClick={onClose}>
                    {t(`dashboard.cancel`)}
                  </Button>
                  <Button colorScheme="teal" onClick={handleFilterSearch}>
                    {t(`dashboard.search`)}
                  </Button>
                </ButtonGroup>
              </Stack>
            </PopoverContent>
          </Popover>

          <Button
            border={'1px solid'}
            mx={1}
            onClick={() => refreshDataTable()}
            variant="outline"
          >
            <RepeatIcon />
          </Button>

          <Button
            mx={1}
            border={'1px solid'}
            onClick={exportData}
            variant="outline"
          >
            <MdOutlineSave />
          </Button>
        </Box>
        <Spacer />
        <Box>
          <InputGroup my={3} width={'20vw'}>
            <Input
              borderColor={'black'}
              placeholder={t(`dashboard.search`)}
              value={searchTerm}
              onChange={handleSearch}
            />
            <InputRightElement>
              <SearchIcon color={'black'} />
            </InputRightElement>
          </InputGroup>
        </Box>
      </Flex>
      {loading ? (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      ) : (
        <DataTable
          value={transactions}
          paginator
          rows={10}
          globalFilter={searchTerm}
          emptyMessage={t(`dashboard.noRecordsFound`)}
        >
          {columns.map(col => (
            <Column
              key={col.field}
              field={col.field}
              header={t(`dashboard.${col.header}`)}
              sortable
              body={col.body}
            />
          ))}
        </DataTable>
      )}

      <Drawer
        isOpen={showDetail}
        size="md"
        placement="right"
        onClose={handleCloseDetails}
      >
        <DrawerOverlay />

        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <Heading as="h4" size="md" mt="9" mb="3">
              {t(`dashboard.shippingDetails`)}
            </Heading>
            <Flex flexDirection={'row'} justifyContent={'end'}>
              {selectedTransaction?.status !== 'PENDING' && (
                <PDFDownloadLink
                  document={
                    <Invoice
                      t={t}
                      data={selectedTransaction}
                      acquirerData={acquirerInfo}
                    />
                  }
                  fileName={`invoice_${moment().format(
                    'DD/MM/YYYY HH:mm'
                  )}.pdf`}
                >
                  <DownloadIcon />
                </PDFDownloadLink>
              )}
            </Flex>
            <VStack
              divider={<StackDivider borderColor="gray.200" />}
              spacing={4}
              align="stretch"
            >
              <Box>
                <Text>{t(`dashboard.transmissionDate`)}</Text>
                <Input
                  isDisabled
                  value={selectedTransaction?.timestamp}
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.sourceCurrency`)}</Text>
                <Input
                  isDisabled
                  value={selectedTransaction?.settlementCurrencyCode || ''}
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.sourceAmount`)}</Text>
                <Input
                  isDisabled
                  value={selectedTransaction?.settlementAmount || ''}
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.destCurrency`)}</Text>
                <Input
                  isDisabled
                  value={selectedTransaction?.currencyCodeRecipient || ''}
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.destAmount`)}</Text>
                <Input
                  isDisabled
                  value={selectedTransaction?.recipientAmount || ''}
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.recipientAccountNumber`)}</Text>
                <Input
                  value={selectedTransaction?.accountNumberRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.paymentType`)}</Text>
                <Input
                  value={selectedTransaction?.paymentTypeRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.recipientBankCode`)}</Text>
                <Input
                  value={selectedTransaction?.bankCodeRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>
            </VStack>

            <Heading as="h4" size="md" mt="20" mb="3">
              {t(`dashboard.senderDetails`)}
            </Heading>
            <VStack
              divider={<StackDivider borderColor="gray.200" />}
              spacing={4}
              align="stretch"
            >
              <Box>
                <Text>{t(`dashboard.firstName`)}</Text>
                <Input
                  isDisabled
                  value={selectedTransaction?.nameSender || ''}
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.lastName`)}</Text>
                <Input
                  isDisabled
                  value={selectedTransaction?.lastNameSender || ''}
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.addressLine`)}</Text>
                <Input
                  value={selectedTransaction?.addressLineSender || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.addressCountry`)}</Text>
                <Input
                  value={selectedTransaction?.addressCountrySender || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>
            </VStack>

            <Heading as="h4" size="md" mt="20" mb="3">
              {t(`dashboard.recipientDetails`)}
            </Heading>

            <VStack
              divider={<StackDivider borderColor="gray.200" />}
              spacing={4}
              align="stretch"
            >
              <Box>
                <Text>{t(`dashboard.firstName`)}</Text>
                <Input
                  value={selectedTransaction?.firstNameRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.lastName`)}</Text>
                <Input
                  value={selectedTransaction?.lastNameRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.addressState`)}</Text>
                <Input
                  value={selectedTransaction?.addressStateRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.addressCity`)}</Text>
                <Input
                  value={selectedTransaction?.addressCityRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.addressLine`)}</Text>
                <Input
                  value={selectedTransaction?.addressLineRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.addressCountry`)}</Text>
                <Input
                  value={selectedTransaction?.addressCountryRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>

              <Box>
                <Text>{t(`dashboard.addressPostalCode`)}</Text>
                <Input
                  value={selectedTransaction?.addressPostalCodeRecipient || ''}
                  isDisabled
                  variant="filled"
                />
              </Box>
            </VStack>
          </DrawerBody>
          <DrawerFooter>
            <Button
              bgColor={'white'}
              color={colors.primary}
              border={'1px solid #982549'}
              onClick={handleCloseDetails}
              m={3}
              fontSize={15}
              boxShadow={'4px 4px 4px rgba(0, 0, 0, 0.1)'}
            >
              {t(`buttons.cancel`)}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default DataProcess;

import { Box, useStyleConfig } from "@chakra-ui/react";



export const Card = (props) => {
    const { variant, children, ...rest } = props;
    const stylesCard = useStyleConfig("Card", { variant });
    return (
    <Box __css={stylesCard} {...rest} my={3} py={5}>
        {children}
    </Box>
    );
}

export const CardHeader = (props) => {
    const { variant, children, ...rest } = props;
    const stylesCardHeader = useStyleConfig("CardHeader", { variant });

    return (
        <Box __css={stylesCardHeader} {...rest}>
        {children}
        </Box>
    );
}

export const CardBody = (props) => {
    const { variant, children, ...rest } = props;
    const stylesCardBody = useStyleConfig("CardBody", { variant });
    return (
    <Box __css={stylesCardBody} {...rest}>
        {children}
    </Box>
    );
}


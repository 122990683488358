import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  Flex,
  Box,
  Text,
  Button,
  Select,
  Divider,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  HStack,
  PinInput,
  PinInputField,
} from '@chakra-ui/react';
import colors from '../../../../assets/custom/colors';
import FileUpload from '../../../../components/fileUpload/FIleUpload';
import { getProducts } from '../../../../redux/action/products';
import { getUserId, getUsername } from '../../../../components/auth/UserData';
import { otp } from '../../../../redux/action/platform';
import { useTranslation } from 'react-i18next';

export const ShippingDetails = ({ nextStep, backStep, setData }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [file, setArchi] = useState('');
  const [products, setProducts] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [pin, setPin] = useState('');
  const { control } = useForm();

  useEffect(() => {
    const products = async () => {
      try {
        const data = await dispatch(getProducts()).unwrap();
        setProducts(data);
      } catch (error) {}
    };
    products();
  }, []);

  const handleChangeProduct = e => {
    let prod = products.find(product => product.id === e.target.value);
    setSelectedProduct(prod);
  };

  const handleSetFile = e => {
    const data = {
      file: file,
      product: selectedProduct,
    };
    setData(data);
    nextStep();
  };

  const handleFileChange = e => {
    setArchi(e);
  };

  const handleBackStep = e => {
    backStep();
  };

  const handleOpen = e => {
    let data = {
      userId: getUserId(),
      transaction: 'OCT_BATCH',
    };
    dispatch(otp(data));
    onOpen();
  };

  return (
    <>
      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        display={'flex'}
      >
        <>
          <Text color={'black'} fontSize="2xl">
            {t('octBatch.sendTransaction')}
            <Divider borderColor={'black'} />
          </Text>
          <Text color={'#7e7477'} fontSize="md" my={5}>
            {t('octBatch.descShipping')}
            <Divider borderColor={'#7e7477'} />
          </Text>

          <Box
            pt={{ base: '120px', md: '25px' }}
            boxShadow="lg"
            borderRadius={10}
            p={8}
            minW={'43vw'}
            maxW={'43vw'}
            border={'1px solid black'}
            justifyContent={'center'}
          >
            <Flex my={5} direction={'column'}>
              <Text mb={2}> {t('labels.product')}:</Text>
              <Select
                onChange={handleChangeProduct}
                placeholder="Select a product"
              >
                {products &&
                  products.map(op => {
                    return (
                      <option key={op.id} value={op.id}>
                        {op.description}
                      </option>
                    );
                  })}
              </Select>
            </Flex>
            <FileUpload
              my={5}
              name="avatar"
              acceptedFileTypes=".csv"
              isRequired={true}
              placeholder="Your file .csv"
              control={control}
              onFileChange={handleFileChange}
            >
              {t('octBatch.upDesc')}
            </FileUpload>

            <Text my={3} fontSize="xs" color={'#c8b8c2'}>
              {t('octBatch.fileDesc')}
            </Text>

            <Flex justifyContent={'end'}>
              <Button
                cursor="pointer"
                bgColor={'white'}
                color={colors.primary}
                border={'1px solid #982549'}
                onClick={handleBackStep}
                fontSize={15}
                boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                alignSelf={'start'}
                mx={5}
              >
                {t('buttons.cancel')}
              </Button>
              <Button
                isDisabled={!(file && selectedProduct)}
                onClick={handleOpen}
              >
                {t('octBatch.confirmTransaction')}
              </Button>
            </Flex>
          </Box>
        </>
      </Flex>

      <Modal isCentered isOpen={isOpen} onClose={onClose} size={'2xl'}>
        <ModalOverlay />
        <ModalContent bgColor="white">
          <ModalHeader>
            <Text>{t('octBatch.confirmTransaction')}</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{t('octBatch.sureTransaction')}</Text>

            <Flex
              direction={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              my={8}
            >
              <HStack>
                <PinInput value={pin} onChange={value => setPin(value)}>
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
              <Text mt={5} color={'#7e7477'} fontSize={'sm'}>
              {t('octBatch.otpDesc')}
              </Text>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              cursor="pointer"
              bgColor={'white'}
              color={colors.primary}
              border={'1px solid #982549'}
              m={3}
              boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
              onClick={onClose}
            >
              {t('buttons.cancel')}
            </Button>
            <Button isDisabled={!pin} onClick={handleSetFile}>
              {t('octBatch.confirmTransaction')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from './interceptor';

export const getTransactions = createAsyncThunk(
  'transactions/get',
  async () => {
    const response = await api.get(
      `/portalapi/oct/product/eedcc851-0bec-47ae-b3c5-31544b5db2e8`
    );
    return response.data;
  }
);

export const getTransactionById = createAsyncThunk(
  'transactions/get',
  async e => {
    const response = await api.get(
      `/portalapi/octbatch/${e.batchId}/transaction/${e.transactionId}`
    );
    return response.data;
  }
);

export const getBatches = createAsyncThunk('batchesQueue/get', async e => {
  const batchesQueue = await api.get(
    `/portalapi/octbatch/waiting/product/${e}`
  );

  const batchesProcessed = await api.get(
    `/portalapi/octbatch/processed/product/${e}`
  );

  return { queue: batchesQueue, processed: batchesProcessed };
});

export const getTransactionsByBatch = createAsyncThunk(
  'transactionsByBatch/get',
  async e => {
    const response = await api.get(`/portalapi/octbatch/${e}/transaction`);
    return response.data;
  }
);

export const registerBatchOct = createAsyncThunk(
  'oct/registerBatch',
  async e => {
    try {
      const data = new FormData();
      data.append('productId', e.product.id);
      data.append('file', e.file);
      const response = await api.post(`/portalapi/octbatch/upload`, data);
      return response;
    } catch (error) {}
  }
);

export const registerOct = createAsyncThunk('oct/register', async e => {
  let data = {
    quoteId: e.quoteId,
    otpToken: e.otpToken,
    productId: e.productId,
    amount: e.amount,
    paymentType: e.paymentType,
    transactionType: e.transactionType,
    paymentMethod: e.paymentMethod,
    sender: {
      name: e.senderFirstName,
      lastName: e.senderLastName,
      accountNumber: 'ewallet:474709;sp=ibcorretora',
      address: {
        country: 'BRA',
        line: e.senderAddressLine,
      },
    },
    recipient: {
      name: e.recipientFirstName,
      lastName: e.recipientLastName,
      bankCode: e.recipientBankCode,
      accountNumber: e.recipientAccountNumber,
      currencyCode: e.recipientCurrencyCode,
      countryCode: e.recipientCountryCode,
      address: {
        country: e.recipientCountry,
        state: e.recipientState,
        city: e.recipientCity,
        postalCode: e.recipientPostalCode,
        line: e.recipientAddressLine,
      },
    },
  };
  try {
    const response = await api.post(`/portalapi/oct`, data);
    return response;
  } catch (error) {
    return error;
  }
});

export const registerRequest = createAsyncThunk(
  'oct/register/request',
  async e => {
    let data = {
      quoteId: '',
      productId: e.productId,
      amount: e.amount,
      paymentType: e.paymentType,
      sender: {
        name: e.senderFirstName,
        lastName: e.senderLastName,
        senderAccountNumber: 'ewallet:474709;sp=ibcorretora',
        address: {
          country: 'BRA',
          line: e.senderAddressLine,
        },
      },
      recipient: {
        name: e.recipientFirstName,
        lastName: e.recipientLastName,
        bankCode: e.recipientBankCode,
        accountNumber: e.recipientAccountNumber,
        currencyCode: e.recipientCurrencyCode,
        countryCode: e.recipientCountryCode,
        address: {
          country: e.recipientCountryCode,
          state: e.recipientState,
          city: e.recipientCity,
          postalCode: e.recipientPostalCode,
          line: e.recipientAddressLine,
        },
      },
    };
    try {
      const response = await api.post(`/portalapi/oct/request `, data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const quoteBatch = createAsyncThunk('oct/quoteBatch', async e => {
  try {
    const response = await api.post(`/portalapi/octbatch/${e}/quote`, []);
    return response.data;
  } catch (error) {}
});

export const quoteUnitary = createAsyncThunk('oct/quoteUnitary', async e => {
  try {
    const response = await api.post(`/portalapi/quote`, e);
    return response.data;
  } catch (error) {}
});

export const confirmBatch = createAsyncThunk('oct/quoteBatch', async e => {
  try {
    const response = await api.post(`/portalapi/octbatch/${e}/oct`, []);
    return response;
  } catch (error) {}
});

export const getBalanceByProd = createAsyncThunk('oct/balance', async e => {
  try {
    const response = await api.get(`/portalapi/balance/product/${e}`);
    return response.data;
  } catch (error) {}
});

export const getRequests = createAsyncThunk('oct/requests', async e => {
  try {
    const response = await api.get(`/portalapi/oct/request/acquirer`);
    return response.data;
  } catch (error) {}
});

export const getRequestById = createAsyncThunk('oct/request', async e => {
  try {
    const response = await api.get(`/portalapi/oct/request/${e}`);
    return response.data;
  } catch (error) {}
});

export const approveRequest = createAsyncThunk('oct/approveReq', async e => {
  try {
    const response = await api.post(`/portalapi/oct/request/approve`, e);
    return response;
  } catch (error) {}
});

export const getFavSender = createAsyncThunk('oct/favSender', async e => {
  try {
    const response = await api.get(
      `/portalapi/favorite/sender?productId=${e.productId}&query=${e.term}`
    );
    return response.data;
  } catch (error) {}
});

export const getFavRecipient = createAsyncThunk('oct/favRecipient', async e => {
  try {
    const response = await api.get(
      `/portalapi/favorite/recipient?productId=${e.productId}&query=${e.term}`
    );
    return response.data;
  } catch (error) {}
});

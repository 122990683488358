import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import baseUrl from '../../config/config';
import { api } from './interceptor';

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  const response = await api.get(`/portalapi/portaluser/usercredentials`);
  return response.data;
});

export const registerUser = createAsyncThunk('users/register', async data => {
  try {
    const response = await api.post(
      `/portalapi/portaluser/usercredentials`,
      data
    );
    return response;
  } catch (error) {}
});

export const deleteUser = createAsyncThunk('users/delete', async id => {
  try {
    const response = await api.delete(
      `/portalapi/portaluser/usercredentials/${id}`
    );
    return response.data;
  } catch (error) {}
});

export const getUser = createAsyncThunk('users/get', async id => {
  try {
    const response = await api.get(
      `/portalapi/portaluser/usercredentials/${id}`
    );
    return response.data;
  } catch (error) {}
});

export const forgotPassword = createAsyncThunk('users/forgot', async data => {
  try {
    const response = await axios.post(
      `${baseUrl}/portalapi/portaluser/forgot`,
      data
    );

    return response;
  } catch (error) {}
});

export const resetPassword = createAsyncThunk('users/reset', async data => {
  try {
    const response = await axios.post(
      `${baseUrl}/portalapi/portaluser/reset`,
      data
    );
    return response;
  } catch (error) {}
});

export const createPassword = createAsyncThunk(
  'users/createNew',
  async data => {
    try {
      const response = await axios.post(
        `${baseUrl}/portalapi/portaluser/create`,
        data
      );
      return response;
    } catch (error) {}
  }
);

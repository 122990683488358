import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import platform from '../slice/platform'

const reducer = combineReducers({
  platform
})

export const store = configureStore({
  reducer
})
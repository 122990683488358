import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from './interceptor';

export const getProducts = createAsyncThunk('products/get', async () => {
  const response = await api.get(`/portalapi/notification/webhooks`);

  console.log('Resoponse ::: ', response.data);
  return response.data;
});

export const getProduct = createAsyncThunk(
  'products/getId',
  async product_code => {
    const response = await api.get(
      `/portalapi/notification/webhooks/${product_code}`
    );
    return response.data;
  }
);

export const registerWebhook = createAsyncThunk(
  'webhook/register',
  async data => {
    data = {
      productCode: data.productCode,
      paymentUrl: data.paymentUrl,
      octUrl: data.octUrl,
    };

    const response = await api.post(`/portalapi/notification/webhooks`, data);
    return response.data;
  }
);

export const deleteWebhook = createAsyncThunk('webhook/delete', async id => {
  try {
    const response = await api.delete(`/portalapi/notification/webhooks/${id}`);
    return response.data;
  } catch (error) {}
});

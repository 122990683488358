import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Flex,
  Box,
  Text,
  Button,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  Grid,
  GridItem,
  ModalOverlay,
  ModalHeader,
  Divider,
} from '@chakra-ui/react';
import { RepeatIcon, SearchIcon } from '@chakra-ui/icons';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  getTransactionsByBatch,
  getTransactionById,
} from '../../../../redux/action/oct';
import { useTranslation } from 'react-i18next';
import colors from '../../../../assets/custom/colors';
import moment from 'moment';

export const BatchById = ({ values, handleBackStep }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [scrollBehavior, setScrollBehavior] = React.useState('inside');
  const [batch, setBatch] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (values) {
      setBatch(values);
    }
  }, [values]);

  useEffect(() => {
    if (batch.batchFileId) {
      const fetchTransactions = async () => {
        try {
          const data = await dispatch(
            getTransactionsByBatch(batch.batchFileId)
          ).unwrap();
          data.map(x =>
            x.timestamp !== ''
              ? (x.timestamp = moment(x.timestamp).format('DD/MM/YYYY'))
              : (x.timestamp = '----')
          );
          setTransactions(data);
        } catch (error) {}
      };
      fetchTransactions();
    }
  }, [batch, dispatch]);

  const deleteButtonTemplate = (rowData, onOpenModal) => {
    return (
      <RepeatIcon
        marginLeft={4}
        cursor="pointer"
        onClick={() => onOpenModal(rowData)}
      />
    );
  };
  const ViewButtonTemplate = rowData => {
    return (
      <Box width={'10px'}>
        <SearchIcon
          marginLeft={4}
          cursor={'pointer'}
          onClick={() => handleViewTransaction(rowData)}
        />
      </Box>
    );
  };

  const handleViewTransaction = async e => {
    try {
      let env = {
        transactionId: e.batchFileTransactionId,
        batchId: e.batchFileId,
      };
      const data = await dispatch(getTransactionById(env)).unwrap();
      setSelectedTransaction(data);
      onOpen();
    } catch (error) {}
  };

  const columns = [
    {
      field: 'batchFileTransactionId',
      header: 'id',
      sortable: true,
    },
    { field: 'status', header: 'status', sortable: true },
    { field: 'timestamp', header: 'timeStamp', sortable: true },
    {
      field: 'iconReprocess',
      color: 'black',
      header: 'reprocess',
      sortable: false,
      body: rowData => deleteButtonTemplate(rowData, handleOpenModal),
    },

    {
      field: 'iconView',
      color: 'black',
      header: 'iconView',
      sortable: false,
      body: ViewButtonTemplate,
    },
  ];
  const handleOpenModal = user => {};
  const handleStep = e => {
    handleBackStep(0);
  };

  return (
    <Flex mt={3} justifyContent="center" direction="column" textAlign="start">
      <Box
        boxShadow="lg"
        border={'1px solid #982549'}
        borderRadius={7}
        bgColor={'white'}
        my={3}
        p={3}
        maxW={'400px'}
      >
        <Text me={1} color={colors.black} fontSize={'1xl'} as={'b'}>
          {t('octBatch.batch')}:
        </Text>
        {batch?.batchFileId}
      </Box>
      <Box
        boxShadow="lg"
        border={'1px solid #982549'}
        borderRadius={7}
        bgColor={'white'}
        my={3}
        p={3}
        maxW={'9vw'}
      >
        <Text as={'b'} me={1} color={colors.black} fontSize={'1xl'}>
          {t('octBatch.date')}:
        </Text>
        {batch?.timestamp}
      </Box>
      <Divider my={6} borderColor={'black'} />
      <Box mb={4} width={'90vw'}>
        <DataTable
          value={transactions}
          paginator
          rows={10}
          emptyMessage="Nenhum registro encontrado"
        >
          {columns.map(col => (
            <Column
              key={col.field}
              field={col.field}
              header={t(`octBatch.${col.header}`)}
              width={col.width}
              sortable={col.sortable}
              body={col.body}
            />
          ))}
        </DataTable>
      </Box>
      <Button
        cursor="pointer"
        bgColor={'white'}
        color={colors.primary}
        border={'1px solid #982549'}
        onClick={handleStep}
        fontSize={15}
        boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
        alignSelf={'start'}
      >
        {t('buttons.goBack')}
      </Button>

      <Modal
        scrollBehavior={scrollBehavior}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('octBatch.transactionDetails')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={5}>
            <Text fontSize="2xl" my={5}>
              {t('octUnitary.formUnitary.senderDetails')}
            </Text>
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('dashboard.currencyCode')}:{' '}
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.recipient?.currencyCode}
                </Text>
              </Box>

              {/* <Box
                bgColor={'white'}
                border={'1px solid #982549'}
                borderRadius={6}
                boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                justifyContent={'start'}
                alignItems={'center'}
                display={'flex'}
                p={3}
                direction={'column'}
              >
                <Text fontSize="sm">
                  Sender Tax Id: {selectedTransaction?.sender?.taxId}
                </Text>
              </Box> */}

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.firstName')}:{' '}
                </Text>

                <Text fontSize="sm">{selectedTransaction?.sender?.name}</Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.senderAccountNumber')}:{' '}
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.sender?.accountNumber}
                </Text>
              </Box>

              <GridItem colSpan={3} borderColor="#dae3e9">
                <Divider />
              </GridItem>

              {/* <Box
                bgColor={'white'}
                border={'1px solid #982549'}
                borderRadius={6}
                boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                justifyContent={'start'}
                alignItems={'center'}
                display={'flex'}
                direction={'column'}
                p={3}
              >
                <Text fontSize="sm">
                  Sender Type: {selectedTransaction?.sender?.type}
                </Text>
              </Box> */}

              {/* <GridItem colSpan={3} borderColor="#dae3e9">
                <Divider />
              </GridItem> */}
              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.senderAddressCountry')}:{' '}
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.sender?.addressCountry}
                </Text>
              </Box>
              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.senderCity')}:{' '}
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.sender?.addressCity}
                </Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.recipientAddressLine')}:{' '}
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.sender?.addressLine}
                </Text>
              </Box>
              {/* 
              <Box
                bgColor={'white'}
                border={'1px solid #982549'}
                borderRadius={6}
                boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                justifyContent={'start'}
                alignItems={'center'}
                display={'flex'}
                p={3}
                direction={'column'}
              >
                <Text fontSize="sm">
                  Recipient Tax Id: {selectedTransaction?.recipient?.taxId}
                </Text>
              </Box> */}
              <GridItem colSpan={3} borderColor="#dae3e9">
                <Divider />
                <Text fontSize="2xl">
                  {' '}
                  {t('octUnitary.formUnitary.recipientDetails')}
                </Text>
              </GridItem>
              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.recipientFirstName')}:{' '}
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.recipient?.firstName}
                </Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.recipientLastName')}:{' '}
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.recipient?.lastName}
                </Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.bankCode')}:{' '}
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.recipient?.bankCode}
                </Text>
              </Box>
              <GridItem colSpan={3} borderColor="#dae3e9">
                <Divider />
              </GridItem>
              {/* <Box
                bgColor={'white'}
                border={'1px solid #982549'}
                borderRadius={6}
                boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                justifyContent={'start'}
                p={3}
                alignItems={'center'}
                display={'flex'}
                direction={'column'}
              >
                <Text fontSize="sm">
                  {t('octUnitary.formUnitary.bankCodeType')}:{' '}
                  {selectedTransaction?.recipient?.bankType}
                </Text>
              </Box> */}

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.bankName')}:{' '}
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.recipient?.bankName}
                </Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.recipientAccountNumber')}:{' '}
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.recipient?.accountNumber}
                </Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.recipientAccountName')}:{' '}
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.recipient?.accountName}
                </Text>
              </Box>
              {/* <Box
                bgColor={'white'}
                border={'1px solid #982549'}
                borderRadius={6}
                boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                p={3}
                justifyContent={'start'}
                alignItems={'center'}
                display={'flex'}
                direction={'column'}
              >
                <Text fontSize="sm">
                {t('octUnitary.formUnitary.bankName')}:{' '}
                  {selectedTransaction?.recipient?.accountNumberType}
                </Text>
              </Box> */}
              <GridItem colSpan={3} borderColor="#dae3e9">
                <Divider />
              </GridItem>

              {/* <Box
                bgColor={'white'}
                border={'1px solid #982549'}
                borderRadius={6}
                boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                justifyContent={'start'}
                alignItems={'center'}
                p={3}
                display={'flex'}
                direction={'column'}
              >
                <Text fontSize="sm">
                  {t('octUnitary.formUnitary.recipientCountryCode')}:{' '}
                  {selectedTransaction?.recipient?.countryCode}
                </Text>
              </Box>
              <GridItem colSpan={3} borderColor="#dae3e9">
                <Divider />
              </GridItem> */}
              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.recipientCountry')}:{' '}
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.recipient?.addressCountry}
                </Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.recipientState')}:
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.recipient?.addressState}
                </Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.recipientCity')}:
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.recipient?.addressCity}
                </Text>
              </Box>
              <GridItem colSpan={3} borderColor="#dae3e9">
                <Divider />
              </GridItem>
              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.recipientPostalCode')}:
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.recipient?.postalCode}
                </Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.recipientAddressLine')}:{' '}
                </Text>
                <Text fontSize="sm">
                  {selectedTransaction?.recipient?.addressLine}
                </Text>
              </Box>

              <Box
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                flexDirection="row"
                padding="22px"
                overflowWrap="break-word"
                background="white"
                boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
                borderRadius="15px"
                p={3}
              >
                <Text color={'#7e7f87'} fontSize="sm">
                  {t('octUnitary.formUnitary.recipientState')}:{' '}
                </Text>

                <Text fontSize="sm">
                  {selectedTransaction?.recipient?.addressSubdivisionCountry}
                </Text>
              </Box>
              <GridItem colSpan={3} borderColor="#dae3e9">
                <Divider />
              </GridItem>
            </Grid>
          </ModalBody>

          <Divider borderColor={'black'} />
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              {t('buttons.cancel')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

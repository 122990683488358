import React from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { getToken } from '../auth/UserData';

function PrivateRoute() {
    if (getToken() == null) {
        return <Navigate to="/login" />
    }
    return  <Outlet />;    
};

export default PrivateRoute;
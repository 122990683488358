import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend) // Carrega os arquivos de tradução
  .use(LanguageDetector) // Detecta a linguagem do navegador
  .use(initReactI18next) // Inicializa o i18n com React
  .init({
    fallbackLng: 'pt', // Idioma padrão
    debug: true,
    interpolation: {
      escapeValue: false, 
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Caminho para os arquivos JSON de tradução
    },
  });

export default i18n;

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Flex,
  Input,
  Button,
  TableContainer,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Spacer,
  Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerFooter,
  DrawerOverlay,
} from '@chakra-ui/react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { DeleteIcon, SearchIcon } from '@chakra-ui/icons';
import colors from '../../../../assets/custom/colors';
import { useTranslation } from 'react-i18next';

import {
  getProducts,
  getProduct,
  registerWebhook,
  deleteWebhook,
} from '../../../../redux/action/webhooks';

const DataProcess = () => {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch();
  const [webhooks, setWebhooks] = useState([]);
  const [selectedWebhook, setselectedWebhook] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEdit, setIsEdit] = useState(false);
  const [isDelModalOpen, setIsDelModalOpen] = useState(false);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      productCode: '',
      paymentUrl: '',
      octUrl: '',
    },
  });

  useEffect(() => {
    if (selectedWebhook) {
      reset({
        productCode: selectedWebhook.productCode,
        paymentUrl: selectedWebhook.paymentUrl,
        octUrl: selectedWebhook.octUrl,
      });
    }
  }, [selectedWebhook, reset]);

  const deleteButtonTemplate = (rowData, onOpenModal) => {
    return (
      <DeleteIcon
        marginLeft={4}
        cursor="pointer"
        onClick={() => onOpenModal(rowData)}
      />
    );
  };
  const ViewButtonTemplate = data => {
    return (
      <SearchIcon
        marginLeft={4}
        cursor={'pointer'}
        onClick={() => handleOnOpen(data)}
      />
    );
  };

  const columns = [
    { field: 'productCode', header: 'productCode', sortable: true },
    { field: 'paymentUrl', header: 'paymentURL', sortable: true },
    { field: 'octUrl', header: 'octURL', sortable: false },
    {
      field: 'iconDelete',
      color: 'black',
      header: 'iconDelete',
      sortable: false,
      body: rowData => deleteButtonTemplate(rowData, handleOpenModal),
    },

    {
      field: 'iconView',
      color: 'black',
      header: 'iconView',
      sortable: false,
      body: ViewButtonTemplate,
    },
  ];

  useEffect(() => {
    const webhooks = async () => {
      try {
        const data = await dispatch(getProducts()).unwrap();
        setWebhooks(data);
      } catch (error) {}
    };
    webhooks();
  }, []);

  async function onSubmit(values) {
    await dispatch(registerWebhook(values)).unwrap();
    toast({
      title: t(`toast.webhookCreateTitle`),
      position: 'top',
      description: t(`toast.webhookCreateDesc`),
      status: 'success',
      duration: 4000,
      isClosable: true,
    });
    const data = await dispatch(getProducts()).unwrap();
    setWebhooks(data);
    onClose();
  }

  const handleNew = e => {
    reset({
      productCode: '',
      paymentUrl: '',
      octUrl: '',
    });

    setIsEdit(false);
    onOpen();
  };

  const handleOpenModal = user => {
    setselectedWebhook(user);
    setIsDelModalOpen(true);
  };

  const handleOnOpen = async webhook => {
    let mer = await dispatch(getProduct(webhook.productCode)).unwrap();
    setselectedWebhook(mer);
    setIsEdit(true);
    onOpen();
  };

  const handleDelete = async data => {
    await dispatch(deleteWebhook(selectedWebhook.id)).unwrap();
    toast({
      title: t(`toast.webhookDeleteTitle`),
      position: 'top',
      description: t(`toast.webhookDeleteDesc`),
      status: 'success',
      duration: 4000,
      isClosable: true,
    });
    const webhooks = await dispatch(getProducts()).unwrap();
    setWebhooks(webhooks);
    setIsDelModalOpen(false);
  };

  const handleEdit = e => {
    setIsEdit(false);
  };

  return (
    <>
      <Flex direction="row" alignItems="center" textAlign="center">
        <Button
          my={2}
          onClick={handleNew}
          variant="outline"
          colorScheme={'#982549'}
        >
          {t(`webhooks.addNew`)} +
        </Button>

        <Drawer isOpen={isOpen} onClose={onClose} size={'xl'} placement="right">
          <DrawerOverlay />
          <DrawerContent backgroundColor={'white'}>
            <DrawerCloseButton />
            <DrawerHeader>Webhook</DrawerHeader>

            <DrawerBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={errors.name}>
                    <FormLabel> {t(`webhooks.productCode`)}</FormLabel>
                    <Input
                      isDisabled={isEdit}
                      id="productCode"
                      placeholder="Enter a product code"
                      {...register('productCode', {
                        required: 'Product Code required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.name && errors.name.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.name}>
                    <FormLabel>{t(`webhooks.paymentURL`)}</FormLabel>
                    <Input
                      isDisabled={isEdit}
                      id="paymentUrl"
                      placeholder="Enter a Payment Url"
                      {...register('paymentUrl', {
                        required: 'Payment Url required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.name && errors.name.message}
                    </FormErrorMessage>
                  </FormControl>
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={errors.street}>
                    <FormLabel>{t(`webhooks.octURL`)}</FormLabel>
                    <Input
                      isDisabled={isEdit}
                      id="octUrl"
                      placeholder="Enter a Oct Url"
                      {...register('octUrl', {
                        required: 'Oct url required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.street && errors.street.message}
                    </FormErrorMessage>
                  </FormControl>
                </Grid>
                <DrawerFooter>
                  <Button
                    cursor="pointer"
                    bgColor={'white'}
                    color={colors.primary}
                    border={'1px solid #982549'}
                    onClick={onClose}
                    m={3}
                    fontSize={15}
                    boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                  >
                    {t(`buttons.cancel`)}
                  </Button>
                  <Button
                    isDisabled={isEdit}
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    {t(`buttons.confirm`)}
                  </Button>
                </DrawerFooter>
              </form>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <Spacer />
      </Flex>
      <DataTable
        value={webhooks}
        paginator
        rows={10}
        emptyMessage="no records found"
      >
        {columns.map(col => (
          <Column
            key={col.field}
            field={col.field}
            header={t(`webhooks.${col.header}`)}
            width={col.width}
            sortable={col.sortable}
            body={col.body}
          />
        ))}
      </DataTable>

      <Modal
        isCentered
        isOpen={isDelModalOpen}
        onClose={() => setIsDelModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent background={'white'}>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalBody>Are you sure you want to delete this Webhook?</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleDelete}>
              Confirm
            </Button>
            <Button variant="ghost" onClick={() => setIsDelModalOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const Webhook = () => {
  return (
    <>
      <Flex ms={3} flexDirection="column" p={8}>
        <Breadcrumb p={2} bg="#fef" color="#333">
          <BreadcrumbItem>
            <BreadcrumbLink href="#">Acquirer</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="/acquirer/products">Product</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="#">Webhook</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <Box ms={12} bgColor={'white'} p={6} boxShadow={'md'} height={'83vh'}>
        <Flex direction="column" pt={{ base: '120px', md: '25px' }}>
          <TableContainer>
            <DataProcess />
          </TableContainer>
        </Flex>
      </Box>
    </>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import baseUrl from '../../config/config';

export const login = createAsyncThunk('platform/login', async data => {
  try {
    const response = await axios.post(
      `${baseUrl}/authapi/oauth/token/portal`,
      data
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}
});

export const otp = createAsyncThunk('platform/otp', async data => {
  try {
    const response = await axios.post(
      `${baseUrl}/portalapi/transactionotp`,
      data
    );
    return response.data;
  } catch (error) {}
});

import React, { useState, useEffect } from 'react';
import {
  Image,
  Flex,
  Box,
  Card,
  Text,
  CardBody,
  Input,
  Divider,
  Button,
  VStack,
  StackDivider,
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { createPassword } from '../../redux/action/users';
import { useDispatch } from 'react-redux';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import colors from '../../assets/custom/colors';
import ReCAPTCHA from 'react-google-recaptcha';

export const CreatePassword = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const hash = params.get('hash');
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [captchaValue, setCaptchaValue] = useState(false);
  const [token, setToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    if (hash) {
      setToken(true);
    }
  }, [hash]);

  const handleRequest = async data => {
    setLoading(true);
    if (password !== confirmPassword) {
      toast({
        title: t(`toast.password`),
        position: 'top',
        description: t(`toast.passwordDontMatch`),
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
      setLoading(false);
    } else {
      const resp = dispatch(
        createPassword({
          hashEmail: hash,
          newSecret: password,
          confirmNewSecret: confirmPassword,
        })
      );
      toast({
        title: t(`toast.password`),
        position: 'top',
        description: t(`toast.passwordCreated`),
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
      navigate('/login');
    }
  };

  const handlePasswordChange = e => setPassword(e.target.value);

  const handleTokenChange = e => setToken(e.target.value);

  const handleConfirmPasswordChange = e => setConfirmPassword(e.target.value);

  return (
    <Flex
      height="93vh"
      p={12}
      direction="row"
      justifyContent={'center'}
      alignItems="center"
      textAlign="center"
    >
      {token && (
        <Card>
          <CardBody minW={'40vw'}>
            <Flex justifyContent={'center'} direction={'column'} mx={5}>
              <Image mx={5} maxHeight={50} src="/img/logo-muevy.svg" />
              <Text fontSize="sm" color={'black'}>
                {t('createPassword.accountPassword')}
              </Text>
            </Flex>

            <Flex
              alignContent={'center'}
              alignItems={'center'}
              justifyContent={'center'}
              direction={'column'}
              mx={5}
            >
              <VStack
                divider={<StackDivider borderColor="gray.200" />}
                spacing={4}
                align="stretch"
                maxW={'20vw'}
              >
                <Flex
                  justifyContent={'start'}
                  alignItems={'start'}
                  direction={'column'}
                  my={3}
                >
                  <Text fontSize="sm" color={'black'}>
                    {t('login.password')}
                  </Text>
                  <Input
                    borderColor={'black'}
                    _placeholder={{ color: 'black' }}
                    color={'black'}
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </Flex>

                <Flex
                  justifyContent={'start'}
                  alignItems={'start'}
                  direction={'column'}
                  my={3}
                >
                  <Text fontSize="sm" color={'black'}>
                    {t('login.confirmPassword')}
                  </Text>
                  <Input
                    type="password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    borderColor={'black'}
                    _placeholder={{ color: 'black' }}
                    color={'black'}
                    placeholder="Confirm Password"
                  />
                </Flex>
              </VStack>
            </Flex>
            <Flex justifyContent={'center'}>
              <ReCAPTCHA
                size="compact"
                sitekey="6LfAAxsqAAAAAKswXBTMTMOyRTHF6fWG7RFMR5xC"
                onChange={value => setCaptchaValue(value)}
              />
            </Flex>
          </CardBody>
          <Divider />
          <Flex
            direction="column"
            justifyContent={'center'}
            alignItems="center"
            textAlign="center"
            bgColor={'white'}
          >
            <Button
              isDisabled={!captchaValue}
              cursor="pointer"
              fontSize={12}
              m={5}
              onClick={handleRequest}
              isLoading={loading}
            >
              {t('createPassword.createNew')}
            </Button>

            <Button
              bgColor={'white'}
              color={colors.primary}
              _hover={{ bg: colors.primary, color: 'white', boxShadow: 'md' }}
              m={2}
              fontSize={12}
              cursor={'pointer'}
              onClick={() => navigate('/login')}
            >
              {t('buttons.goBack')}
            </Button>
          </Flex>
        </Card>
      )}

      {!token && (
        <Flex
          direction="column"
          pt={{ base: '120px', md: '25px' }}
          justifyContent="center"
          alignItems="center"
          height={'90vh'}
          p={5}
          mt={10}
        >
          <Flex
            direction="column"
            bgColor={'white'}
            border={'1px solid'}
            borderColor={'#7e7477'}
            justifyContent="center"
            alignItems="center"
            p={32}
            borderRadius={10}
          >
            <Text color={'#7e7477'} fontSize="md" my={5}>
              {t('createPassword.token')}
              <Divider borderColor={'#7e7477'} />
            </Text>
            <CloseIcon boxSize={16} m={6} color="red.500" />
            <Box my={6}>
              <Button onClick={() => navigate('/login')} mx={3}>
                {t('buttons.goBack')}
              </Button>
            </Box>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export const exportToCSV = (data, filename) => {
    filename = filename + "_transactions.csv";
    const header = Object.keys(data[0]).join(",") + "\n";
    const csvContent = data.map(item => 
        Object.values(item).join(",")
    ).join("\n");

    const fileContent = header + csvContent;
    const blob = new Blob([fileContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

import { createSlice } from '@reduxjs/toolkit';
import { login } from '../action/platform';

var init = {
  errorLogin: null,
  isLogged: false,
};

export const platform = createSlice({
  name: 'platform',
  initialState: init,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.errorLogin = null;
      state.isLogged = true;
    });

    builder.addCase(login.rejected, (state, action) => {
      state.errorLogin = action.payload;
    });
  },
});

export default platform.reducer;

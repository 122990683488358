import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from './interceptor';

export const getAcquirers = createAsyncThunk('acquirers/get', async () => {
  const response = await api.get(`/portalapi/acquirer`);
  return response.data;
});

export const getAcquirer = createAsyncThunk('acquirer/get', async id => {
  const response = await api.get(`/portalapi/acquirer/${id}`);
  return response.data;
});

export const registerAcquirer = createAsyncThunk(
  'acquirer/register',
  async data => {
    data = {
      name: data.businessName,
      document: data.document,
      technicalContact: {
        name: data.contactName,
        phone: data.contactPhone,
        email: data.contactEmail,
      },
      financialContact: {
        name: data.financialContactName,
        phone: data.finacialContactPhone,
        email: data.finacialContactEmail,
      },
      paymentOriginationCountry: data.paymentOriginationCountry,
      addresses: [
        {
          type: 'MAIN',
          street: data.street,
          number: data.number,
          complement: data.complement,
          zipCode: data.zipCode,
          city: data.city,
          state: data.state,
          country: data.country,
        },
      ],
    };
    const response = await api.post(`/portalapi/acquirer`, data);
    return response.data;
  }
);

export const deleteAcquirer = createAsyncThunk('acquirer/delete', async id => {
  try {
    const response = await api.delete(`/portalapi/acquirer/${id}`);
    return response.data;
  } catch (error) {}
});

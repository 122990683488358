import React, { useState } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { ShippingDetails } from './Steps/ShippingDetails';
import { OverView } from './Steps/OverView';
import { GridList } from './Steps/GridList';
import { BatchById } from './Steps/BatchById';

export const Oct = () => {
  const [step, setStep] = useState(0);
  const [transactionData, setTransactionData] = useState(null);
  const [batches, setBatch] = useState({});

  const handleData = e => {
    setTransactionData(e);
  };

  const handleStep = e => {
    let i = step + 1;
    if (e !== undefined) {
      setTransactionData(e);
    }
    setStep(i);
  };

  const handleRestartStep = e => {
    e === 0 ? setStep(e) : setStep(1);
  };

  const handleBackStep = e => {
    let i = step - 1;
    setStep(i);
  };

  const handleNew = e => {
    setStep(0);
  };

  const handleDetail = e => {
    setBatch(e);
    setStep(3);
  };

  return (
    <>
      <Flex
        direction="column"
        pt={{ base: '120px', md: '25px' }}
        justifyContent="center"
        alignItems="center"
        alignContent={'center'}
        display={'flex'}
        minHeight={'90vh'}
      >
        {step === 0 && (
          <Box
            ms={14}
            bgColor={'white'}
            p={6}
            boxShadow={'md'}
            minHeight={'90vh'}
          >
            <GridList
              detail={handleDetail}
              nextStep={handleStep}
              backStep={handleBackStep}
            />
          </Box>
        )}
        {step === 1 && (
          <Box
            ms={12}
            mt={1}
            bgColor={'white'}
            p={6}
            boxShadow={'md'}
            justifyContent={'center'}
            alignContent={'center'}
            borderRadius={8}
          >
            <ShippingDetails
              nextStep={handleStep}
              backStep={handleBackStep}
              setData={handleData}
            />
          </Box>
        )}
        {step === 2 && (
          <Box
            ms={12}
            mt={1}
            bgColor={'white'}
            justifyContent={'center'}
            alignContent={'center'}
            p={6}
            boxShadow={'md'}
          >
            <OverView
              values={transactionData}
              newTransaction={handleNew}
              nextStep={handleStep}
              restartStep={handleRestartStep}
            />
          </Box>
        )}

        {step === 3 && (
          <Box ms={12} bgColor={'white'} p={6} boxShadow={'md'} height={'90vh'}>
            <BatchById handleBackStep={handleRestartStep} values={batches} />
          </Box>
        )}
      </Flex>
    </>
  );
};

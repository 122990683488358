import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Flex, Box, Text, Button, Spinner, Divider } from '@chakra-ui/react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { registerBatchOct, registerOct } from '../../../../redux/action/oct';
import { useTranslation } from 'react-i18next';
import colors from '../../../../assets/custom/colors';
import { getProfle } from '../../../../components/auth/UserData';

export const OverView = ({ values, newTransaction, nextStep, restartStep }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [processed, setProcessed] = useState(false);
  const [send, setSend] = useState(false);

  useEffect(() => {
    if (!processed && !send) {
      sendTransaction();
    }
  }, [processed]);

  const sendTransaction = async e => {
    let req;
    if (getProfle() === 'ACQUIRER') {
      req = await dispatch(registerBatchOct(values)).unwrap();
    } else {
      // req = await dispatch(registerBatchOct(values)).unwrap();
    }
    if (req?.status === 200) {
      setProcessed(true);
      setSend(true);
    } else {
      setProcessed(true);
      setSend(false);
    }
  };

  const handleNew = async e => {
    newTransaction();
  };

  const handleNavigate = e => {
    restartStep();
  };

  return (
    <>
      {!processed && (
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          boxShadow="lg"
          minH={'30vh'}
          bgColor={'white'}
          border={'1px solid'}
          borderColor={'#7e7477'}
          borderRadius={10}
          p={5}
        >
          <Text color={'black'} fontSize="2xl">
            {t('octUnitary.overView.processing')}
          </Text>

          <Spinner
            mt={10}
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="red.500"
            size="xl"
          />

          <Box
            bgColor={'white'}
            border={'1px solid'}
            borderColor={'#7e7477'}
            borderRadius={10}
            maxW={'20vw'}
            mt={10}
            boxShadow="lg"
            p={5}
          >
            <Text>{t('octUnitary.overView.overDesc')}</Text>
          </Box>
        </Flex>
      )}

      {processed && (
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          p={5}
        >
          <Flex
            direction="column"
            bgColor={'white'}
            border={'1px solid'}
            borderColor={'#7e7477'}
            justifyContent="center"
            alignItems="center"
            p={32}
            borderRadius={10}
          >
            <Text color={'black'} fontSize="2xl">
              {t('octUnitary.overView.transaction')}{' '}
              {send
                ? t('octUnitary.overView.processed')
                : t('octUnitary.overView.failed')}
              <Divider borderColor={'black'} />
            </Text>

            <Text color={'#7e7477'} fontSize="md" my={5}>
              {send
                ? t('octUnitary.overView.processedDesc')
                : t('octUnitary.overView.errorDesc')}
              <Divider borderColor={'#7e7477'} />
            </Text>
            {send ? (
              <CheckIcon
                boxSize={16}
                m={6}
                border={'1px solid green'}
                borderRadius={'full'}
                p={2}
                color="green.500"
              />
            ) : (
              <CloseIcon boxSize={16} m={6} color="red.500" />
            )}
            <Box>
              <Button
                cursor="pointer"
                bgColor={'white'}
                color={colors.primary}
                border={'1px solid #982549'}
                m={3}
                fontSize={15}
                onClick={handleNavigate}
                boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
              >
                {t('octUnitary.overView.newTransaction')}
              </Button>
              <Button onClick={handleNew} mx={3} type="submit">
                {t('octUnitary.overView.transactions')}
              </Button>
            </Box>
          </Flex>
        </Flex>
      )}
    </>
  );
};

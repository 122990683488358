import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Flex,
  Input,
  Button,
  TableContainer,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Spacer,
  Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerFooter,
  DrawerOverlay,
  Divider,
  Text,
  GridItem,
} from '@chakra-ui/react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDispatch } from 'react-redux';
import {
  getAcquirers,
  getAcquirer,
  registerAcquirer,
  deleteAcquirer,
} from '../../../redux/action/acquirer';
import { useToast } from '@chakra-ui/react';
import { DeleteIcon, SearchIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import colors from '../../../assets/custom/colors';

const DataProcess = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      businessName: '',
      businessId: '',
      type: 'MAIN',
      street: '',
      number: '',
      complement: '',
      zipCode: '',
      city: '',
      state: '',
      country: '',
      contactName: '',
      contactPhone: '',
      contactEmail: '',
      financialContactName: '',
      financialContactPhone: '',
      finacialContactEmail: ''
    },
  });
  const dispatch = useDispatch();
  const [acquirers, setAcquirers] = useState([]);
  const [selectedAcquirer, setselectedAcquirer] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEdit, setIsEdit] = useState(false);
  const [isDelModalOpen, setIsDelModalOpen] = useState(false);
  const deleteButtonTemplate = (rowData, onOpenModal) => {
    return (
      <DeleteIcon
        marginLeft={4}
        cursor="pointer"
        onClick={() => onOpenModal(rowData)}
      />
    );
  };

  const ViewButtonTemplate = data => {
    return (
      <SearchIcon
        marginLeft={4}
        cursor={'pointer'}
        onClick={() => handleOnOpen(data)}
      />
    );
  };

  const formatPhoneNumber = phone => {
    if (!phone) return '';
    return phone.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
  };

  const columns = [
    { field: 'name', header: 'businessName', sortable: true },
    {
      field: 'technicalContact.name',
      header: 'contactName',
      sortable: false,
    },
    {
      field: 'technicalContact.phone',
      header: 'contactPhone',
      sortable: false,
      body: rowData => formatPhoneNumber(rowData.technicalContact.phone),
    },
    {
      field: 'technicalContact.email',
      header: 'contactEmail',
      sortable: false,
    },
    {
      field: 'iconDelete',
      color: 'black',
      header: 'iconDelete',
      sortable: false,
      body: rowData => deleteButtonTemplate(rowData, handleOpenModal),
    },
    {
      field: 'iconView',
      color: 'black',
      header: 'iconView',
      sortable: false,
      body: ViewButtonTemplate,
    },
  ];
  useEffect(() => {
    const acquirers = async () => {
      try {
        const data = await dispatch(getAcquirers()).unwrap();
        setAcquirers(data);
      } catch (error) {}
    };
    acquirers();
  }, []);

  useEffect(() => {
    if (selectedAcquirer) {
      reset({
        businessName: selectedAcquirer.name,
        document: selectedAcquirer.document,
        street: selectedAcquirer.addresses
          ? selectedAcquirer.addresses[0].street
          : '',
        number: selectedAcquirer.addresses
          ? selectedAcquirer.addresses[0].number
          : '',
        complement: selectedAcquirer.addresses
          ? selectedAcquirer.addresses[0].complement
          : '',
        zipCode: selectedAcquirer.addresses
          ? selectedAcquirer.addresses[0].zipCode
          : '',
        city: selectedAcquirer.addresses
          ? selectedAcquirer.addresses[0].city
          : '',
        state: selectedAcquirer.addresses
          ? selectedAcquirer.addresses[0].state
          : '',
        country: selectedAcquirer.addresses
          ? selectedAcquirer.addresses[0].country
          : '',
        contactName: selectedAcquirer.technicalContact.name,
        contactPhone: selectedAcquirer.technicalContact.phone,
        contactEmail: selectedAcquirer.technicalContact.email,
        financialContactName: selectedAcquirer.financialContact.name,
        financialContactPhone: selectedAcquirer.financialContact.phone,
        finacialContactEmail: selectedAcquirer.financialContact.email,
        paymentOriginationCountry: selectedAcquirer.paymentOriginationCountry,
      });
    }
  }, [selectedAcquirer, reset]);

  async function onSubmit(values) {
    await dispatch(registerAcquirer(values)).unwrap();
    toast({
      title: t('toast.acquirerCreateTitle'),
      position: 'top',
      description: t('toast.acquirerCreateDesc'),
      status: 'success',
      duration: 4000,
      isClosable: true,
    });
    const data = await dispatch(getAcquirers()).unwrap();
    setAcquirers(data);
    onClose();
  }

  const handleNew = e => {
    reset({
      businessName: '',
      businessId: '',
      type: 'MAIN',
      street: '',
      number: '',
      complement: '',
      zipCode: '',
      city: '',
      state: '',
      country: '',
      contactName: '',
      contactPhone: '',
      contactEmail: '',
      financialContactName: '',
      financialContactPhone: '',
      finacialContactEmail: ''
    });
    onOpen();
  };

  const handleOpenModal = async acquirer => {
    setselectedAcquirer(acquirer);
    setIsDelModalOpen(true);
  };

  const handleOnOpen = async acquirer => {
    acquirer = await dispatch(getAcquirer(acquirer.id)).unwrap();
    setselectedAcquirer(acquirer);
    setIsEdit(true);
    onOpen(true);
  };

  const handleDelete = async data => {
    await dispatch(deleteAcquirer(selectedAcquirer.id)).unwrap();
    toast({
      title: t('toast.acquirerDeleteTitle'),
      position: 'top',
      description: t('toast.acquirerDeleteDesc'),
      status: 'success',
      duration: 4000,
      isClosable: true,
    });
    const acquirers = await dispatch(getAcquirers()).unwrap();
    setAcquirers(acquirers);
    setIsDelModalOpen(false);
  };

  const handleModalClose = async () => {
    setIsEdit(false);
    const acquirers = await dispatch(getAcquirers()).unwrap();
    setAcquirers(acquirers);
    onClose();
  };

  return (
    <>
      <Flex direction="column" alignItems="start" textAlign="center">
        <Button
          my={2}
          onClick={handleNew}
          variant="outline"
          colorScheme={'#982549'}
        >
          {t('acquirer.addNew')} acquirer +
        </Button>

        <Divider my={6} borderColor={'black'} />

        <Drawer
          isOpen={isOpen}
          onClose={handleModalClose}
          size={'xl'}
          placement="right"
        >
          <DrawerOverlay />
          <DrawerContent backgroundColor={'white'}>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">
              {!isEdit ? t('acquirer.createNew') : t('acquirer.details')}
            </DrawerHeader>

            <DrawerBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                {isEdit && (
                  <FormControl isInvalid={errors.name}>
                    <FormLabel>Acquirer ID</FormLabel>
                    <Input isDisabled={isEdit} value={selectedAcquirer.id} />
                  </FormControl>
                )}
                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={errors.businessName}>
                    <FormLabel>{t('acquirer.businessName')}</FormLabel>
                    <Input
                      isDisabled={isEdit}
                      id="businessName"
                      placeholder={t('placeholders.businessName')}
                      {...register('businessName', {
                        required: 'Business Name required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.businessName && errors.businessName.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.document}>
                    <FormLabel>{t('merchant.document')}</FormLabel>
                    <Input
                      id="document"
                      isDisabled={isEdit}
                      placeholder={t('placeholders.document')}
                      {...register('document', {
                        required: 'Document required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.document && errors.document.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.paymentOriginationCountry}>
                    <FormLabel>
                      {t('acquirer.paymentOriginationCountry')}
                    </FormLabel>
                    {!isEdit && (
                      <Select
                        {...register('paymentOriginationCountry', {
                          required: 'Payment origination country required',
                        })}
                        id="paymentOriginationCountry"
                        isDisabled={isEdit}
                        placeholder={t('placeholders.option')}
                      >
                        <option value={'USA'}>USA</option>
                        <option value={'BRA'}>BRA</option>
                        <option value={'GBR'}>GBR</option>
                        <option value={'CAN'}>CAN</option>
                        <option value={'FRA'}>FRA</option>
                        <option value={'DEU'}>DEU</option>
                        <option value={'JPN'}>JPN</option>
                        <option value={'CHN'}>CHN</option>
                        <option value={'IND'}>IND</option>
                        <option value={'AUS'}>AUS</option>
                        <option value={'RUS'}>RUS</option>
                      </Select>
                    )}
                    {isEdit && (
                      <Input
                        isDisabled={isEdit}
                        id="paymentOriginationCountry"
                        placeholder={t(
                          'placeholders.paymentOriginationCountry'
                        )}
                        {...register('paymentOriginationCountry', {
                          required: 'Payment origination country required',
                        })}
                      />
                    )}
                    <FormErrorMessage>
                      {errors.paymentOriginationCountry &&
                        errors.paymentOriginationCountry.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.street}>
                    <FormLabel>{t('acquirer.streetAddress')}</FormLabel>
                    <Input
                      isDisabled={isEdit}
                      id="street"
                      placeholder={t('placeholders.streetAddress')}
                      {...register('street', {
                        required: 'Street Address required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.street && errors.street.message}
                    </FormErrorMessage>
                  </FormControl>
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={errors.number}>
                    <FormLabel>{t('acquirer.addressNumber')}</FormLabel>
                    <Input
                      isDisabled={isEdit}
                      id="number"
                      placeholder={t('placeholders.addressNumber')}
                      {...register('number', {
                        required: 'number Address required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.number && errors.number.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.zipCode}>
                    <FormLabel>{t('acquirer.zipcode')}</FormLabel>
                    <Input
                      isDisabled={isEdit}
                      id="zipCode"
                      placeholder={t('placeholders.zipcode')}
                      {...register('zipCode', {
                        required: 'ZipCode required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.zipCode && errors.zipCode.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.city}>
                    <FormLabel>{t('acquirer.city')}</FormLabel>
                    <Input
                      isDisabled={isEdit}
                      id="city"
                      placeholder={t('placeholders.city')}
                      {...register('city', {
                        required: 'City required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.city && errors.city.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.complement}>
                    <FormLabel>{t('acquirer.complement')}</FormLabel>
                    <Input
                      isDisabled={isEdit}
                      id="complement"
                      placeholder={t('placeholders.complement')}
                      {...register('complement')}
                    />
                    <FormErrorMessage>
                      {errors.complement && errors.complement.message}
                    </FormErrorMessage>
                  </FormControl>
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={errors.state}>
                    <FormLabel>{t('acquirer.state')}</FormLabel>
                    <Input
                      isDisabled={isEdit}
                      id="state"
                      placeholder={t('placeholders.state')}
                      {...register('state', {
                        required: 'State required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.state && errors.state.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.country}>
                    <FormLabel>{t('acquirer.countryCode')}</FormLabel>
                    {!isEdit && (
                      <Select
                        {...register('country', {
                          required: 'Country Code required',
                        })}
                        id="country"
                        isDisabled={isEdit}
                        placeholder={t('placeholders.option')}
                      >
                        <option value={'USA'}>USA</option>
                        <option value={'BRA'}>BRA</option>
                        <option value={'GBR'}>GBR</option>
                        <option value={'CAN'}>CAN</option>
                        <option value={'FRA'}>FRA</option>
                        <option value={'DEU'}>DEU</option>
                        <option value={'JPN'}>JPN</option>
                        <option value={'CHN'}>CHN</option>
                        <option value={'IND'}>IND</option>
                        <option value={'AUS'}>AUS</option>
                        <option value={'RUS'}>RUS</option>
                      </Select>
                    )}
                    {isEdit && (
                      <Input
                        isDisabled={isEdit}
                        id="country"
                        {...register('country', {
                          required: 'Country Code required',
                        })}
                      />
                    )}
                    <FormErrorMessage>
                      {errors.country && errors.country.message}
                    </FormErrorMessage>
                  </FormControl>

                  <GridItem colSpan={3} borderColor="#dae3e9">
                    <Text as="u" fontSize="2xl">
                      Technical Contact
                    </Text>
                  </GridItem>

                  <FormControl isInvalid={errors.contactName}>
                    <FormLabel>{t('acquirer.contactName')}</FormLabel>
                    <Input
                      isDisabled={isEdit}
                      id="contactName"
                      placeholder={t('placeholders.contactName')}
                      {...register('contactName', {
                        required: 'Contact Name required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.contactName && errors.contactName.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.contactPhone}>
                    <FormLabel>{t('acquirer.contactPhone')}</FormLabel>
                    <Input
                      isDisabled={isEdit}
                      id="contactPhone"
                      placeholder={t('placeholders.contactPhone')}
                      {...register('contactPhone', {
                        required: 'Contact Phone required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.contactPhone && errors.contactPhone.message}
                    </FormErrorMessage>
                  </FormControl>
                </Grid>

                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={errors.contactEmail}>
                    <FormLabel>{t('acquirer.contactEmail')}</FormLabel>
                    <Input
                      isDisabled={isEdit}
                      id="contactEmail"
                      placeholder={t('placeholders.contactEmail')}
                      {...register('contactEmail', {
                        required: 'Contact Email required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.contactEmail && errors.contactEmail.message}
                    </FormErrorMessage>
                  </FormControl>
                </Grid>

                <GridItem my={4} colSpan={3} borderColor="#dae3e9">
                  <Text as="u" fontSize="2xl">
                    Financial Contact
                  </Text>
                </GridItem>

                <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={2}>
                  <FormControl isInvalid={errors.financialContactName}>
                    <FormLabel>{t('acquirer.contactName')}</FormLabel>
                    <Input
                      isDisabled={isEdit}
                      id="financialContactName"
                      placeholder={t('placeholders.contactName')}
                      {...register('financialContactName', {
                        required: 'Financial Contact Name required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.financialContactName &&
                        errors.financialContactName.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.financialContactPhone}>
                    <FormLabel>{t('acquirer.contactPhone')}</FormLabel>
                    <Input
                      isDisabled={isEdit}
                      id="financialContactPhone"
                      placeholder={t('placeholders.contactPhone')}
                      {...register('financialContactPhone', {
                        required: 'Financial Contact Phone required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.financialContactPhone &&
                        errors.financialContactPhone.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.finacialContactEmail}>
                    <FormLabel>{t('acquirer.contactEmail')}</FormLabel>
                    <Input
                      isDisabled={isEdit}
                      id="finacialContactEmail"
                      placeholder={t('placeholders.contactEmail')}
                      {...register('finacialContactEmail', {
                        required: 'Financial Contact Email required',
                      })}
                    />
                    <FormErrorMessage>
                      {errors.finacialContactEmail &&
                        errors.finacialContactEmail.message}
                    </FormErrorMessage>
                  </FormControl>
                </Grid>

                <DrawerFooter>
                  <Button
                    cursor="pointer"
                    bgColor={'white'}
                    color={colors.primary}
                    border={'1px solid #982549'}
                    onClick={onClose}
                    m={3}
                    fontSize={15}
                    boxShadow={'4px 4px 4px 4px rgba(0, 0, 0, 0.1)'}
                  >
                    {t('buttons.cancel')}
                  </Button>
                  {!isEdit && (
                    <Button isLoading={isSubmitting} type="submit">
                      {t('buttons.confirm')}
                    </Button>
                  )}
                </DrawerFooter>
              </form>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <Spacer />
      </Flex>
      <DataTable
        value={acquirers}
        paginator
        rows={10}
        emptyMessage="no records found"
      >
        {columns.map(col => (
          <Column
            key={col.field}
            field={col.field}
            header={t(`merchant.${col.header}`)}
            width={col.width}
            sortable={col.sortable}
            body={col.body}
          />
        ))}
      </DataTable>

      <Modal
        isCentered
        isOpen={isDelModalOpen}
        onClose={() => setIsDelModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent background={'white'}>
          <ModalHeader>{t('modals.confirmDeletion')}</ModalHeader>
          <ModalBody>{t('modals.infoSure')} acquirer?</ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={() => setIsDelModalOpen(false)}>
              {t('buttons.cancel')}
            </Button>
            <Button mx={2} colorScheme="blue" mr={3} onClick={handleDelete}>
              {t('buttons.confirm')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const Acquirers = () => {
  useEffect(() => {}, []);
  return (
    <>
      <Flex
        ms={3}
        flexDirection="column"
        pt={{ base: '80px', md: '25px' }}
        p={8}
      >
        <Breadcrumb p={2} bg="#fef" color="#333">
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Muevy</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="#">Acquirers</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>
      <Box ms={12} bgColor={'white'} p={6} boxShadow={'md'} minH={'83vh'}>
        <Flex direction="column" pt={{ base: '120px', md: '25px' }}>
          <TableContainer>
            <DataProcess />
          </TableContainer>
        </Flex>
      </Box>
    </>
  );
};

export const getProfle = () => {
  return sessionStorage.getItem('profile') || null;
};

export const getToken = () => {
  return sessionStorage.getItem('token') || null;
};

export const getAcquirerId = () => {
  return sessionStorage.getItem('acquirerId') || null;
};

export const getUsername = () => {
  return sessionStorage.getItem('username') || null;
};

export const getUserId = () => {
  return sessionStorage.getItem('userId') || null;
};

export const setUserSession = (
  username,
  token,
  profile,
  userId,
  acquirerId
) => {
  sessionStorage.setItem('userId', userId);
  sessionStorage.setItem('username', username);
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('profile', profile);
  sessionStorage.setItem('acquirerId', acquirerId);
};

export const removeUserSession = () => {
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('username');
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('profile');
};
